<template>
  <div style="text-align: center">
    <div class="title" :style="{ color: color }">{{ title }}</div>
    <div class="separator-outer">
      <div class="separator bg-primary style-liner"/>
    </div>
    <p style="margin-top: 10px; max-width: 700px;margin-left: auto;margin-right: auto;color: #9897a0">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'MiddleTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#333'
    }
  }
}
</script>

<style scoped lang="scss">
.title{
  font-size: 36px;
  line-height: 45px;
  margin-bottom: 20px;
  font-weight: 600;
}

.separator-outer {
  overflow: hidden;

  & > .separator {
    width: 20px;
    display: inline-block;
    height: 3px;
    margin-bottom: 10px;
    position: relative;

    &:before, &:after{
      content: "";
      position: absolute;
      top: 50%;
      left: 30px;
      width: 70px;
      height: 1px;
      background: #cecece;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &:before{
      left: auto;
      right: 30px;
    }
  }
}
</style>
