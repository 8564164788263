<template>
  <container style="margin-top: 50px">
    <a-row :gutter="[32]">
      <a-col :xxl="10" :xl="10" :lg="12" :md="24" :sm="24" style="margin-bottom: 20px">
        <!--        <a-image  height="100%"></a-image>-->
        <img
          src="https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/DREAM-YOUR-LIFE/image.jpg"
          style="width: 100%; height: 100%" />
      </a-col>
      <a-col :xxl="14" :xl="14" :lg="12" :md="24" :sm="24">
        <left-title main="辰辉始炬 智熠寰宇 " sub="DREAM YOUR LIFE" />
        <p style="margin-top: 20px">
          深圳市前海辰宇投资咨询有限公司是全国领先的海外咨询服务机构，旗下囊括广东辰耀律师事务所、辰思留学、辰邦移民、香港保险经纪行和香港秘书服务公司。公司业务广泛，致力于为客户提供海外留学、环球移民、环球投资、海外保险、海外房产及家族信托传承等专业服务，业务范围遍及全球20多个国家及地区，资产管理规模已逾百亿。
        </p>
        <p>
          辰宇集团成立于2016年7月，总部设于深圳，并在全国范围内设立了多家分公司，覆盖了包括中国香港、广州、成都、武汉、石家庄、佛山、珠海、杭州、上海、郑州、厦门、太原、昆明等多个重要城市。集团现有员工逾千人，其中近70%为海归，30%为硕士博士学历。辰宇核心管理团队拥有超过13年行业经验，已为中国过万组中高净值家庭提供专业、优质、高效的海外咨询服务。
        </p>

        <p>
          我们携手众多国内外知名的会计师事务所、私人银行、保险机构、基金券商等合作伙伴，打造全方位“1+N”的国际私人资管服务平台，满足个人、家庭及企业的多样化需求。作为财智升级先行者，我们致力于为客户提供专业的全球资产配置方案和服务，持续为客户创造最大价值。展望未来，辰宇集团将继续秉持着“驱动财富增长，实现智慧人生”的企业愿景，实行双轨发展策略，即扩大产品种类与拓展中国市场，以助力更多优质客户实现不同阶段的财智人生，引领环球财务规划行业快速发展。
        </p>
        <left-title style="margin-top: 40px" main="核心竞争力" sub="CORE COMPETITIVENESS" font-size="16px" />
        <ol class="list-num-count">
          <li>Global 全球视野</li>
          <li>Professional 专业规划</li>
          <li>Integrated 综合配置</li>
          <!-- <li>以客户利益为首，深入了解及梳理客户的财富管理需求</li> -->
        </ol>

      </a-col>
    </a-row>
  </container>

</template>

<script>
import LeftTitle from '@/components/common/LeftTitle'
export default {
  name: 'About',
  components: { LeftTitle }
}
</script>

<style scoped lang="scss">
.list-num-count {
  margin-top: 20px;
  counter-reset: li;
  padding-left: 0;

  & li {
    position: relative;
    margin: 0 0 18px 30px;
    padding: 4px 16px;
    list-style: none;
    line-height: 20px;

    &:before {
      content: counter(li, decimal);
      counter-increment: li;
      position: absolute;
      top: 0;
      left: -28px;
      width: 28px;
      height: 28px;
      padding: 5px;
      color: #fff;
      background: #efbb20;
      font-weight: 700;
      text-align: center;
      font-size: 12px;
      -webkit-border-radius: 100px;
      border-radius: 100px;
    }
  }
}
</style>
