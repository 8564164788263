<template>
  <div class="bg-img-fix" :style="{ 'background-image': `url(${img})` }" style="position: relative;">
    <container class="bg-img-fix overlay-black-dark" padding="70px 12px">
      <middle-title style="margin-bottom: 40px" title="管理层寄语" class="text-white" color="#ffffff" />
      <a-row type="flex" :gutter="[32, 32]">
        <a-col v-for="(opinion, index) in opinions" :xxl="8" :xl="8" :lg="12" :md="24" :sm="24" :key="index">
          <div class="opinion-box">
            <p>{{ opinion.content }}</p>
          </div>
          <div class="opinion-detail">
            <div class="author clearfix">
              <img :src="opinion.avatar" width="80" height="80">
            </div>
            <div>
              <strong class="text-white" style="display: block;margin-bottom: 5px;font-size: 15px">{{ opinion.title
                }}</strong>
              <span class="text-primary">{{ opinion.subTitle }}</span>
            </div>
          </div>
        </a-col>
      </a-row>
    </container>
  </div>

</template>

<script>
import MiddleTitle from '@/components/common/MiddleTitle'
export default {
  name: 'Opinion',
  components: { MiddleTitle },
  data() {
    return {
      img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/management-message/image1.jpg',
      avatarImg: require('@/assets/image/background/img3.png'),
      opinions: [
        {
          avatar: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/avatar/Daniel.png',
          title: 'Daniel Zeng',
          subTitle: 'CEO',
          content: '辰宇一直以来不忘初心，致力于成为中国顶尖的国际投资咨询企业的标杆，有您的支持，我们必将全力以赴，做到最好！我们深信辰宇的服务是以「人」为本。作为客户的私人财富管家，我们真诚主动地为客户肩负起全保理财的重要使命，达至保障、理财、退休和传承的四大目标。'
        },
        {
          avatar: require('@/assets/image/picture/avatar/Kingsley Wong.jpg'),
          title: 'Kingsley Wong',
          subTitle: 'Partner & COO',
          content: '志同者，不宜山海为远。辰宇人，定当奋勇争先。大家在辰宇学会的不仅是理财知识，更多的是如何做一个值得信任的有价值的人。辰宇成长的每一步都值得我们去细细回味，因为当中饱含着辰宇人辛勤的汗水和不懈的努力，更凝聚着大家成功的喜悦。未来我们有更坚定的信心，跟随时代的步伐，与行业共同进步，成为行业的一个“财智升级的领导者”。'
        },
        {
          avatar: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/avatar/wewe.png',
          title: 'Wewe Huang',
          subTitle: 'Partner & CTO',
          content: '没有哪个行业可以今天做，明天就有成效。沉淀一百个小时、一千个小时甚至一万个小时，才能做好为客户解决的那十分钟。基于此，普及大家一些简单的理财观念，让一些大众客户得到提升，也能需时变成高净值人士。让更多人懂得怎么用钱；人不是钱的奴隶，而是主人。我们抱着这样的初心与信念努力前行，期待更多的同道志士加入我们。'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.opinion-box {
  color: #777;
  background-color: rgba(255, 255, 255, .9);
  padding: 30px;
  height: 240px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 45px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #efefef;
  }
}

.opinion-detail {
  padding: 25px 0 20px 20px;
  display: flex;
  align-items: center;

  & .author {
    margin-right: 15px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    position: relative;
    background: #fff;
    display: inline-block;
    border: 5px solid #fff;

    & img {
      vertical-align: middle;
      border-style: none;
      transform-style: inherit;
      height: auto;
      max-width: 100%;
      border-radius: 100%;
    }
  }
}
</style>
