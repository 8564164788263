<template>
  <container>
    <middle-title style="margin-bottom: 40px" title="全方位金融服务"
      description="辰宇作为全国领先的环球财务规划服务机构，为客户提供海外留学、环球移民、环球投资、海外保险、海外房产及家族信托传承等专业服务。" />
    <a-row type="flex" :gutter="[60, 32]">
      <a-col v-for="(service, index) in services" :xxl="12" :xl="12" :lg="12" :md="24" :key="index">
        <div style="display: flex">
          <div style="text-align: center;margin-right: 20px">
            <div class="bg-secondary"
              style="display: inline-block; height: 80px; width: 80px; text-align: center;padding: 25px;margin-bottom: 20px;border-radius: 40px">
              <AccountBookFilled class="text-primary" style="font-size: 30px" />
            </div>
          </div>
          <div>
            <h5>{{ service.title }}</h5>
            <p>{{ service.content }}</p>
          </div>
        </div>
      </a-col>
    </a-row>
  </container>
</template>

<script>
import { AccountBookFilled } from '@ant-design/icons-vue'
import MiddleTitle from '@/components/common/MiddleTitle'
export default {
  name: 'Services',
  components: { MiddleTitle, AccountBookFilled },
  data() {
    return {
      services: [
        {
          title: '环球投资 Global Investment',
          content: 'S&P指数保本计划、环球基金储蓄计划、派息型基金储蓄计划、契约型美元固收基金、美元打新基金、香港投资移民、新加坡家族办公室'
        },
        {
          title: '海外房产 Overseas Property',
          content: '海外主流国家房产投资咨询、海外安家/置业/托管一站式服务、海外土地投资、海房VIP定制化服务'
        },
        {
          title: '法务咨询 Legal Consultation',
          content: '民商事诉讼、行政诉讼、刑事辩护、私募基金业务、股权业务模块、房地产及建设工程业务、企业法律顾问'
        },
        {
          title: '保险咨询 Insurance Consultation',
          content: '重疾险、高端医疗险、美金储蓄分红计划、大额人寿、保单信托项目、养老社区项目'
        },
        {
          title: '留学教育 Overseas Education',
          content: '常规留学申请、海外名校直录、科研课题提升、国际竞赛冲刺、名企实习输送、标化考试助考、专业测评规划、导师辅导护航'
        },
        {
          title: '企业服务 Enterprise Service',
          content: '香港秘书服务、ODI/37号文/FDI投资/海外架构、香港金融牌照办理买卖、粤港两地车牌办理'
        },
        {
          title: '移民规划 Immigration Planning',
          content: '全球26个国家和地区的一站式移民服务，包含技术移民、雇主担保移民、投资移民等不同方式，实现环球身份规划'
        },
        {
          title: '信托服务 Trust Service',
          content: '家族信托、婚姻信托、保险金信托、上市高管信托、员工持股信托、创业者信托'
        }
      ]
    }
  }
}
</script>

<style scoped></style>
