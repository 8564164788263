<template>
  <a-carousel arrows>
    <template #prevArrow>
      <div class="custom-slick-arrow" style="left: 10px;z-index: 100">
        <left-outlined />
      </div>
    </template>
    <template #nextArrow>
      <div class="custom-slick-arrow" style="right: 10px">
        <right-outlined />
      </div>
    </template>
    <div v-for="(img, index) in imgs" :key="index">
      <div class="content" :style="{ 'background-image': `url(${img})` }" style="background-position: 50% 80%;">
        <container class="container">
          <div>
            <div>
              <div style="font-weight: bold; font-size: 36px;color: #FDC716 !important">您的环球财务规划顾问</div>
              <div style="font-weight: bold; font-size: 56px;color: white !important; padding-bottom: 10px">
                YOUR GLOBAL FINANCIAL PLANNER
              </div>
              <div style="font-size: 15px; color: white; max-width: 750px">
                深圳市前海辰宇投资咨询有限公司是全国领先的环球财务规划服务机构，为客户提供海外留学、环球移民、环球投资、海外保险、海外房产及家族信托传承等专业服务。
              </div>
            </div>
          </div>
        </container>
      </div>
    </div>
  </a-carousel>
</template>

<script>
import LeftTitle from '@/components/common/LeftTitle'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
import { AspectImage } from 'vue-imgs'

export default {
  name: 'HomeCarousel',
  // eslint-disable-next-line vue/no-unused-components
  components: { LeftTitle, LeftOutlined, RightOutlined, AspectImage },
  data() {
    return {
      // imgs: [require('@/assets/image/picture/11.jpg'), require('@/assets/image/picture/7.jpg'), require('@/assets/image/picture/8.jpg'), require('@/assets/image/picture/9.jpg')]
      // img: require('@/assets/image/background/bn1.png')
      imgs: [
        'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/banner/image1.jpg',
        'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/banner/image2.jpg',
        'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/banner/image3.jpg'
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.ant-carousel {

  //border-bottom: 5px solid rgb(45, 50, 57);
  & ::v-deep(.slick-slide) {
    background: rgba(0, 0, 0, 0.4);
    overflow: hidden;
    height: 650px;

    & .content {
      padding-bottom: 0;
      overflow: hidden;
      background-position: center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      height: 650px;
      width: 100%;

      &>.container {
        background-color: rgba(0, 0, 0, 0.4);
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  & ::v-deep(.slick-arrow.custom-slick-arrow) {
    width: 60px;
    height: 60px;
    font-size: 30px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 15px;
    border-radius: 30px;
  }

  & ::v-deep(.custom-slick-arrow:before) {
    display: none;
  }

  & ::v-deep(.custom-slick-arrow:hover) {
    opacity: 0.5;
  }

  & ::v-deep(.slick-slide h3) {
    color: #fff;
  }
}
</style>
