<template>
  <container>
    <middle-title style="margin-bottom: 40px" title="线下沙龙" :description="description" />
    <a-row :gutter="[16, 16]">
      <a-col :lg="8" :xl="6" :xxl="6" :md="12" :sm="12" :xs="24" v-for="(img, index) in imgList" :key="index">
        <img style="cursor: pointer;width:100% ;object-fit: cover;" :src="img" />
      </a-col>
    </a-row>
  </container>
</template>

<script>
import { AspectImage } from 'vue-imgs'
import MiddleTitle from '@/components/common/MiddleTitle'
export default {
  name: 'Salon',
  // eslint-disable-next-line vue/no-unused-components
  components: { MiddleTitle, AspectImage },
  data() {
    return {
      description: '作为财智升级的先行者，辰宇每年都会在全国举办线上线下主题沙龙活动，向客户分享理财知识，同时，不断打磨高品质的财商教育课程，为客户提供提升财商认知的平台，实现个体的财富留存与传承。',
      imgList: [
        'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/salon/image1.jpg',
        'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/salon/image2.jpg',
        'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/salon/image3.png',
        'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/salon/image4.jpg'
      ]
    }
  }
}
</script>

<style scoped></style>
