<template>
  <div>
    <home-carousel />

    <about />

    <div class="bg-img-fix" :style="{ 'background-image': `url(${img})` }"
      style=" margin-top: 60px;position: relative;">
      <container class="container text-white overlay-black-dark" padding="70px 12px">
        <div class="flex-column-sm align-items-start-sm align-items-center"
          style="display: flex; justify-content: space-between">
          <div style="margin-right: 20px">
            <h2 class="text-white" style="font-weight: bold">我们的征途是星辰大海</h2>
            <p style="max-width: 750px">
            </p>
          </div>
          <a-button style="font-weight: bold" type="primary" size="large">加入我们</a-button>
        </div>
      </container>
    </div>

    <services style="margin-top: 70px" />

    <salon style="margin-top: 70px" />

    <opinion style="margin-top: 70px" />

    <blog-grid style="margin-top: 70px" />
  </div>

</template>

<script>
import Container from '@/components/common/Container'
import HomeCarousel from '@/components/home/HomeCarousel'
import Services from '@/components/home/Services'

import Salon from '@/components/home/Salon'
import Opinion from '@/components/home/Opinion'
import BlogGrid from '@/components/home/BlogGrid'
import About from '@/components/home/About'
export default {
  name: 'Home',
  components: { About, BlogGrid, Opinion, Salon, Services, HomeCarousel, Container },
  data() {
    return {
      img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/management-message/image2.jpg'
    }
  }
}
</script>

<style scoped lang="scss"></style>
